import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'toggle', 'entry' ]

  connect() {
    let mode = localStorage.getItem('cfr-blogs-view-mode')
    this.toggleTarget.checked = (mode == 'full')
  }

  toggleView() {
    if (this.toggleTarget.checked) {
      localStorage.setItem('cfr-blogs-view-mode', 'full')
      $(this.entryTargets).addClass('expanded')
    } else {
      localStorage.removeItem('cfr-blogs-view-mode')
      $(this.entryTargets).removeClass('expanded')
    }
  }
}

// [...temp1.children].sort((a,b)=>a.data.timespan>b.data-timespan?1:-1).forEach(node=>temp1.appendChild(node))
