import { Controller } from "@hotwired/stimulus"
import List from 'list.js'
const axios = require('axios').default

export default class extends Controller {

  static targets = [
    
    'outputTable',
    'outputLegend',
    'outputTextarea',
    'download',
    
    'dailyData',
    'weeklyData',
    'monthlyData',

    'arbitraryFromData',
    'arbitraryToData',
    
    'card',

    'preloader',
    'summary',

    'calculations',
    'calcSize',

    'token'
  ]

  connect() {

    $(this.dailyDataTarget).Zebra_DatePicker()
    $(this.arbitraryFromDataTarget).Zebra_DatePicker()
    $(this.arbitraryToDataTarget).Zebra_DatePicker()

    var options = {
      valueNames: [
        'date',
        'issuer',
        'coupon',
        'rating',
        'size',
        'tenor',
        'final',
        'lead',
        'ipt',
        'guidance',
        'ipt_pxd',
        'priced',
        'trading',
        'change',
        'books',
        'x_cvrd',
        'nic_direct',
        'nic_comps',
        'nic_avg'
      ],
      item: function(values) {
        return `
          <div class='item'>
            <div><a class='date' target='_blank' href='/deals/${values.nanoid}'>${values.date}</a></div>
            <div><a class='ticker' target='_blank' href='/issuers/${values.issuer}'>${values.issuer}</a></div>
            <div class='coupon'></div>
            <div class='rating'></div>
            <div class='size'></div>
            <div class='tenor'></div>
            <div class='final'></div>
            <div class='lead'></div>
            <div class='ipt'></div>
            <div class='guidance'></div>
            <div class='ipt_pxd'></div>
            <div class='priced'></div>
            <div class='trading'></div>
            <div class='change'></div>
            <div class='books'></div>
            <div class='x_cvrd'></div>
            <div class='nic_direct'></div>
            <div class='nic_comps'></div>
            <div class='nic_avg'></div>
          </div>
        `
      }
    }

    let outputList = new List('output_table', options)
    window.outputList = outputList
  }

  generate(event) {

    this.hideOutput()
    this.preloaderTarget.classList.add('active')

    window.outputList.clear()

    let type = event.params.type

    let timespan = this.dailyDataTarget.value
    if (type == 'weekly') timespan = this.weeklyDataTarget.value
    if (type == 'monthly') timespan = this.monthlyDataTarget.value
    if (type == 'arbitrary') timespan = this.arbitraryFromDataTarget.value + ':' + this.arbitraryToDataTarget.value

    $.ajax({
      method: 'put',
      data: {
        type: type,
        timespan: timespan,
        format: 'json',
      },
      url: '/reports/generate',
      success: (data) => {

        if (data.records.length != 0) {
          this.outputTextareaTarget.value = JSON.stringify(data)
          this.showOutput()
        } else {
          this.hideOutput()
        }

        window.outputList.add(data.records)

        this.preloaderTarget.classList.remove('active')
        
        this.summaryTarget.innerHTML = data.summary.count_text
        this.calcSizeTarget.innerHTML = data.summary.total_size
      }
    })
  }

  // axios.put('/reports/export', {
  //   authenticity_token: this.tokenTarget.value,
  //   data: this.outputTextareaTarget.value,
  //   format: 'xlsx'
  // })

  export(event) {
    console.log(event.currentTarget)

    fetch('/reports/export', {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': this.tokenTarget.value,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: this.outputTextareaTarget.value,
        format: 'xlsx'
      })
    })
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'export.xlsx'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  switchCard(event) {
    if( $(event.currentTarget).hasClass('selected') ) { return }

    this.hideOutput()
    this.cardTargets.forEach((element, index) => {
      element.classList.remove('selected')
    })
    event.currentTarget.classList.add('selected')
  }

  hideOutput() {
    this.outputLegendTarget.classList.add('uk-hidden')
    this.outputTableTarget.classList.add('uk-hidden')
    this.downloadTarget.classList.add('uk-hidden')
    this.calculationsTarget.classList.add('uk-hidden')
    this.summaryTarget.innerHTML = ''
    this.outputTextareaTarget.value = ''
  }

  showOutput() {
    this.outputLegendTarget.classList.remove('uk-hidden')
    this.outputTableTarget.classList.remove('uk-hidden')
    this.downloadTarget.classList.remove('uk-hidden')
    this.calculationsTarget.classList.remove('uk-hidden')
  }
}
