import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.initRedactor()
    this.initIssuers()
    this.initDeals()
    this.initAttachmentRemoval()
  }

  updateCheckboxes(event) {
    let ig = 1
    let hy = 6
    let em = 16

    let ig_group = [2, 3, 4, 5, 12, 14, 18, 20]
    let hy_group = [7, 8, 9, 10, 13, 15, 19, 22]
    let em_group = [17, 21]

    let current_id = parseInt(event.target.getAttribute('data-checkbox-id'))

    if (!event.target.checked) { return }

    if (ig_group.includes(current_id)) {
      document.querySelector(`input[data-checkbox-id="${ig}"]`).checked = true
    }

    if (hy_group.includes(current_id)) {
      document.querySelector(`input[data-checkbox-id="${hy}"]`).checked = true
    }

    if (em_group.includes(current_id)) {
      document.querySelector(`input[data-checkbox-id="${em}"]`).checked = true
    }
  }

  initRedactor() {
    $R('#content', {
      plugins: ['table'],
      fileUpload: '/blogs/file-upload',
      imageUpload: '/blogs/image-upload',
      fileAttachment: '#file-target',
      callbacks: {
        file: {
          uploaded: function(file, response) {
            let uniq = $(file).find('a').data('file');
            let existing = $('#blog_files_ids').val();
            existing += uniq + ' '
            $('#blog_files_ids').val(existing);
          }
        }
      }
    })
  }

  initIssuers() {
    var issuers_selectize = $('#blog_issuer_ids');
    issuers_selectize.selectize({
      persist: true,
      allowEmptyOption: false,
      labelField: 'selectize_name',
      labelField: 'selectize_name',
      searchField: ['id', 'selectize_name'],
      render: {
        item: function(item, escape) {
            return '<div class="item"><span>' + escape(item.selectize_name) + '</span></div>';

        },
        option: function(item, escape) {
          return '<div>' +
            '<span class="title">' +
              '<span class="name">' + escape(item.selectize_name) + '</span>' +
            '</span>' +
          '</div>';
        }
      }
    })
  }

  initDeals() {
    var deals_selectize = $('#blog_deal_ids');
    deals_selectize.selectize({
      persist: true,
      allowEmptyOption: false,
      labelField: 'selectize_name',
      searchField: ['id', 'selectize_name'],
      render: {
        item: function(item, escape) {
          return '<div class="item"><span>' + escape(item.selectize_name) + '</span></div>';
        },
        option: function(item, escape) {
          return '<div>' +
            '<span class="title">' +
              '<span class="name">' + escape(item.selectize_name) + '</span>' +
            '</span>' +
          '</div>'
        }
      }
    })
  }

  initAttachmentRemoval() {
    $('#file-target > span.redactor-file-item > span.redactor-file-remover').on('click', function () {
      let uniq = $(this).prev().data('file');
      let existing = $('#blog_files_ids').val();
      let updated = existing.replace(uniq + ' ', '')
      $('#blog_files_ids').val(updated)
      $(this.closest('span.redactor-file-item')).remove()
    })
  }
}
