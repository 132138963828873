import { Controller } from "@hotwired/stimulus"
const axios = require('axios').default

export default class extends Controller {
  static targets = [
    'issuersSelect',
  ]

  connect() {
    $(this.issuersSelectTarget).selectize({
      create: true,
      sortField: "text",
    })

    $('#deal_date').Zebra_DatePicker()
    $('#deal_maturity').Zebra_DatePicker()
    $('#deal_settlement').Zebra_DatePicker()
    $('#deal_call_date').Zebra_DatePicker()

    $R('.editor-value', {
      plugins: ['table']
    });

    let perp_checkbox = $('input#deal_perpetual')
    let term_input = $('input#deal_term')

    perp_checkbox.on('change', function() {
      if (this.checked) {
        term_input.prop('disabled', true)
      } else {
        term_input.prop('disabled', false)
      }
    })

    let checkboxes_clear = $('.section.better-checkboxes span.clear');
    checkboxes_clear.on('click', function() {
      $(this).closest('.better-checkboxes')
        .find('input.radio_buttons')
        .prop('checked', false)
    })
  }

  changeSelectedIssuer() {
    // let id = this.issuersSelectTarget.value
    // let url = `/issuers/${id}/fa`
    // let checkbox = document.getElementById('deal_theme_ids_36')

    // axios({
    //   method: 'get',
    //   url: url,
    // })
    // .then(function (response) {
    //   let res = response.data.fa
    //   checkbox.checked = res
    // })
  }
}
