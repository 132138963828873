require("@rails/ujs").start()
require("@rails/activestorage").start()

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// Prepend from env.js and globally registering jQuery
const jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("./jquery-dataTables")
require("./jquery-easy-autocomplete")
require("./selectize")
require("./zebra-datepicker")
require("./highlight")

require("./redactor.min.js")
require("./table.min.js")
// require('packs/nicescroll') -> ?

require("chartkick")
require("chart.js")

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);
window.UIkit = UIkit;

import "controllers"