import { Controller } from "@hotwired/stimulus"
const axios = require('axios').default

export default class extends Controller {
  static targets = [ 'item', 'items' ]
  static values = { id: String }

  connect() {
    let url = '/faved'

    axios({
      method: 'get',
      url: url,
    })
    .then(function (response) {
      let res = response.data
      window.themes = [...res.themes]
      window.issuers = [...res.issuers]
      window.sectors = [...res.sectors]
      window.countries = [...res.countries]
    })
  }
}
