import { Controller } from "@hotwired/stimulus"

import _, { map } from 'underscore'
window._ = _

export default class extends Controller {

  connect() {

    if (window.themes) {
      let data = this.element.querySelector('.each').dataset

      let issuers = data.issuers.split(' ').map((i) => Number(i))
      if (issuers == [0]) { issuers = [] }
  
      let themes = data.themes.split(' ').map((i) => Number(i))
      if (themes == [0]) { themes = [] }
  
      let sectors = data.sectors.split(' ').map((i) => Number(i))
      if (sectors == [0]) { sectors = [] }
  
      let countries = data.countries.split(' ').map((i) => Number(i))
      if (countries == [0]) { countries = [] }
  
      let presence = _.intersection(window.issuers, issuers).length +
        _.intersection(window.themes, themes).length +
        _.intersection(window.sectors, sectors).length +
        _.intersection(window.countries, countries).length
  
      if (presence == 0) {
        this.element.classList.add('uk-hidden')
      }
    }
  }
}
