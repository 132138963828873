import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let mode = localStorage.getItem('cfr-blogs-view-mode')

    if (mode == 'full') {
      this.element.classList.add('expanded')
    }

    this.element.classList.remove('uk-hidden')
  }
}
