import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'table',
    'xls',

    'from',
    'to',

    'ig',
    'hy',
    'em',
    'split',

    'term',
    'perpetual',

    'issuers',
    'tickers',
    'countries',

    'rootSector',
    'subSector',

    'issuerValues',
    'tickerValues',
    'countryValues',
  ]

  connect() {
    this.initParams()
    this.initDatepickers()
    this.initTable()
    this.initSubsector()
    this.initIssuers()
    this.initTickers()
    this.initCountries()

    this.processCheckedLmCheckbox()
  }

  initParams() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    window.params = Object.fromEntries(urlSearchParams.entries())
  }

  initDatepickers() {
    $(this.fromTarget).Zebra_DatePicker({
      pair: $(this.toTarget)
    })
    $(this.toTarget).Zebra_DatePicker()
  }

  initTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        "searching": false,
        "lengthChange": false,
        "pageLength": 30,
        "info": false,
        "bAutoWidth" : false,
        "bDestroy": true,
        "order": [[ 1, "desc" ]],
      })
    }
  }

  initIssuers() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
  }

  checkIgHy() {
    if (this.splitTarget.checked) {
      this.igTarget.checked = true
      this.hyTarget.checked = true
    }
  }

  uncheckSplit() {
    if (!this.igTarget.checked || !this.hyTarget.checked) {
      this.splitTarget.checked = false
    }
  }

  blurTerm() {
    if (this.perpetualTarget.checked == true) {
      this.termTarget.disabled = 'disabled'
    } else {
      this.termTarget.removeAttribute('disabled')
    }
  }

  initSubsector() {
    let root = $(this.rootSectorTarget)
    let sub = $(this.subSectorTarget)

    if (window.params.hasOwnProperty('search[sub_sector]')) {
      let subsector_param = params['search[sub_sector]']
      $.ajax({
        url: `/deals/search_subsectors/${root.val()}`,
        success: function(data) {
          if (JSON.stringify(data).length === 2) {
            sub.prop('disabled', true)
            sub.children().remove()
          } else {
            sub.prop('disabled', false)
            sub.children().remove()
            for (var prop in data) {
              sub.append(new Option(prop, data[prop]))
            }
            sub.val(subsector_param)
          }
        }
      })
    }
  }

  searchSubsector() {
    let root = $(this.rootSectorTarget)
    let sub = $(this.subSectorTarget)

    if (root.val() != 0) {
      $.ajax({
        url: `/deals/search_subsectors/${root.val()}`,
        success: function(data) {
          if (JSON.stringify(data).length === 2) {
            sub.prop('disabled', true)
            sub.children().remove();
          } else {
            sub.prop('disabled', false)
            sub.children().remove();
            for (var prop in data) {
              sub.append(new Option(prop, data[prop]));
            }
          }
        }
      })
    } else {
      sub.children().remove();
      sub.prop('disabled', true)
    }
  }

  initIssuers() {
    $(this.issuersTarget).selectize({
      persist: true,
      allowEmptyOption: false,
    })

    let control_issuers = $(this.issuersTarget)[0].selectize
    if (this.issuerValuesTarget.value != '') {
      this.issuerValuesTarget.value.split(' ').forEach(function(item, index) {
        control_issuers.addItem(item)
      })
    }
  }

  initTickers() {
    $(this.tickersTarget).selectize({
      persist: true,
      allowEmptyOption: false,
    })

    let control_tickers = $(this.tickersTarget)[0].selectize
    if (this.tickerValuesTarget.value != '') {
      this.tickerValuesTarget.value.split(' ').forEach(function(item, index) {
        control_tickers.addItem(item)
      })
    }
  }

  initCountries() {
    $(this.countriesTarget).selectize({
      persist: true,
      allowEmptyOption: false,
    })

    let control_countries = $(this.countriesTarget)[0].selectize
    if (this.countryValuesTarget.value != '') {
      this.countryValuesTarget.value.split(' ').forEach(function(item, index) {
        control_countries.addItem(item)
      })
    }
  }

  processCheckedLmCheckbox() {
    let lmCheckbox = document.querySelector("input#search_theme_lm")
    let lmInput = document.querySelector("select#search_lm_type")

    if (lmCheckbox.checked == true) {
      lmInput.selectedIndex = 0
      lmInput.disabled = true
    }

    lmCheckbox.addEventListener('change', function() {
      if (this.checked) {
        lmInput.selectedIndex = 0
        lmInput.disabled = true
      } else {
        lmInput.disabled = false
      }
    })
  }
}
